// similar-items.js
import React from 'react'
//import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"
import BlogItem from './sidebar-blog-item'
import { SimilarItemsFactory } from './similar-items-factory'
//import { getPostsFromQuery } from "../../utils/blog"
import Utils from "../../utils"

const SimilarItems = ({ items }) => {
  return(
    <section className="similar-items">
      {items.map((item, i) => {
        let description = Utils.trimToN(item?.item?.body?.summary, 75, 3)
        return(
        <BlogItem
           key={i}
           title={item?.item?.title}
           description={description}
           date={item?.item?.created}
           path={item?.item?.path?.alias}
           image={item?.item?.relationships?.field_image?.image_style_uri?._294x192 }
           //className={`${ breakpoints.md ? 'col-md-6' : classRow } ${'item-'+index} blog-item`}
           //includeTags={includeTags}
           //categories={article?.relationships?.field_category}
           className="similar-item"
           nodeType={item?.iternal?.type}
        />
      )}
    )}
    </section>
  )
}


// (1.) Query for items
export default (props) => (
  <StaticQuery
    query={graphql`
      query SimilarItems {
        posts: allNodeBlogPostGoel(
          limit: 1000
        )  {
          nodes {
            internal {
              type
            }
            langcode
            title
            created(formatString: "DD MMMM YYYY")
            body {
              summary
            }
            path {
              alias
            }
            relationships {
              field_category{
                name
                path {
                  alias
                }
              }
              field_tag {
                name
                path {
                  alias
                }
              }
              field_image {
                image_style_uri {
                  large
                  medium
                  thumbnail
                  wide
                  _294x192
                  _541x359
                  _653_432
                }
              }
            }
        }
      }
    }
    `}
    render={data => {
      const { category, tags, currentItemSlug, lang } = props;

      const getPostsFromQuery = (posts) => {
        let items = [];
        items = posts?.nodes?.map((post, ii) => {
          return (
            post
          )
        })
        return items;
      }

      // (2.) Marshall the response into items
      const items = getPostsFromQuery(data?.posts);

      // (3.) Use a SimilarItemsFactory to get my similar items
      const similarItems = new SimilarItemsFactory(
        items, currentItemSlug, lang
      )
      .setMaxItems(4)
      .setCategory(category)
      .setTags(tags)
      .setLanguage(lang)
      .getItems()

      // (4.) Render it
      return (
        <SimilarItems
          items={similarItems}
        />
      )
    }}
  />
)
