// similar-items-factory.js
import { includes, orderBy } from 'lodash'

export class SimilarItemsFactory {
  // (1.) Create by passing in items, currentSlug
  constructor (items, currentItemSlug, lang) {
    // (2.) Don't include the current item in items list
    this.items = items.filter(
      (aItem) => aItem.path.alias !== currentItemSlug && aItem.langcode === lang
    );

    this.currentItemSlug = currentItemSlug;
    // (3.) Set default values
    this.maxItems = 3;
    this.category = []; //custom drupal array field
    this.tags = [];
    this.language = lang;
  }

  // (4.) Builder pattern usage
  setMaxItems (m) {
    this.maxItems = m;
    return this;
  }

  setCategory (aCategory) {
    this.category = aCategory;
    return this;
  }

  setTags (tagsArray) {
    this.tags = tagsArray;
    return this;
  }

  setLanguage (language) {
    this.languange = language;
    return this;
  }

  getItems () {
    const { category, tags, items, maxItems, language } = this;
    // (5.) We use an Identity Map to keep track of score
    const identityMap = {};

    if (!!tags === false || tags.length === 0) {
      console.error('SimilarItemsFactory: Tags not provided, use setTags().')
      return [];
    }

    if (!!category === false) {
      console.error('SimilarItemsFactory: Category not provided, use setCategory().')
      return [];
    }

    if (!!language === false) {
      console.error('SimilarItemsFactory: Lang not provided, use setLanguage().')
      return [];
    }

    function getSlug (item) {
      return item.path.alias;
    }

    function addToMap (item) {
      const slug = getSlug(item);
      if (!identityMap.hasOwnProperty(slug)) {
        identityMap[slug] = {
          item: item,
          points: 0
        }
      }
    }

    // (7.) For category matches, we add 2 points
    function addCategoryPoints (item, category) {
      const categoryPoints = 2;
      const slug = getSlug(item);

      //if (item.relationships.feld_category === category) {
      //  identityMap[slug].points += categoryPoints;
      //}
      item.relationships.field_category.forEach((aCategory) => {
        if (includes(category, aCategory.name)) {
          identityMap[slug].points += categoryPoints;
        }
      })
    }

    function addLanguagePoints (item, lang) {
      const languagePoints = 3;
      const slug = getSlug(item);

      if (item.langcode === lang) {
        identityMap[slug].points += languagePoints;
      }
    }

    // (8.) For tags matches, we add 1 point
    function addTagsPoints (item, tags) {
      const tagPoint = 1;
      const slug = getSlug(item);

      item.relationships.field_tag.forEach((aTag) => {
        if (includes(tags, aTag.name)) {
          identityMap[slug].points += tagPoint;
        }
      })
    }

    function getIdentityMapAsArray () {
      return Object.keys(identityMap).map((slug) => identityMap[slug]);
    }

    // (6.) Map over all items, add to map and add points
    for (let item of items) {
      addToMap(item);
      addCategoryPoints(item, category);
      addTagsPoints(item, tags);
      addLanguagePoints (item, language)
    }

    // (9.) Convert the identity map to an array
    const arrayIdentityMap = getIdentityMapAsArray();

    // (10.) Use a lodash utility function to sort them
    // by points, from greatest to least
    const similarItems = orderBy(
      arrayIdentityMap, ['points'], ['desc']
    )

    // (11. Take the max number items requested)
    return similarItems.splice(0, maxItems);
  }
}
